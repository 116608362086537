<template>
    <div class="">
        <a-tabs type="card" @change="tabItemClick" :activeKey="type">
            <!--            <a-tab-pane key="1" tab="样品分类"></a-tab-pane>-->
            <a-tab-pane key="3" tab="存放地点"></a-tab-pane>
            <a-tab-pane key="4" tab="试剂组别"></a-tab-pane>
            <a-tab-pane key="5" tab="试剂单位"></a-tab-pane>
            <!--            <a-tab-pane key="2" tab="检测能力分组"></a-tab-pane>-->
            <a-tab-pane key="6" tab="检验资质"></a-tab-pane>
            <a-tab-pane key="8" tab="仪器分类"></a-tab-pane>
            <a-tab-pane key="10" tab="检验结果单位"></a-tab-pane>
            <a-tab-pane key="11" tab="人员类别"></a-tab-pane>
            <a-tab-pane key="12" tab="物品类别"></a-tab-pane>
            <!--            <a-tab-pane key="13" tab="仪器条件"></a-tab-pane>-->
            <!--            <a-tab-pane key="14" tab="资质管理"></a-tab-pane>-->
        </a-tabs>
        <div class="tablewrap">
            <table class="layui-table" lay-size="sm">
                <thead>
                <tr>
                    <th>序号</th>
                    <th>{{ checkTableTitle() }}</th>
                    <th>排序</th>
                    <th>操作</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item,key) in ziduan_list">
                    <td>{{ item.id }}</td>
                    <td>
                        <a-input v-model="item.name" class="w200" @keyup.enter="saveItem(item)"/>
                    </td>
                    <td>
                        <a-input v-model="item.sort" class="w200"/>
                    </td>
                    <td style="text-align: left">
                        <a-button type="primary" @click="saveItem(item)">保存</a-button>
                        <a-button type="danger" class="ml20" @click="delItem(item)">删除</a-button>
                    </td>
                </tr>

                <tr>
                    <td></td>
                    <td>
                        <a-input ref="clickPosition" v-model="create_data.name" placeholder="" class="w200" @keyup.enter="Create_sampletype()"/>
                    </td>
                    <td>
                        <a-input v-model="create_data.sort" placeholder="" class="w200"/>
                    </td>
                    <td style="text-align: left">
                        <a-button type="primary" @click="Create_sampletype()">增加</a-button>
                    </td>
                </tr>

                </tbody>
            </table>
            <!--分页-->
            <a-pagination :current="page" :pageSize="pagesize" @change="onChange" :total="count" v-if="count>pagesize"/>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                create_data: {
                    id: 0,
                    name: '',
                    sort: '0'
                },
                table_title: '',
                ziduan_list: [],
                page: 1,
                type: 1,
                count: 0,
                pagesize: 0,

            };
        },
        watch: {
            $route: {
                handler() {
                    this.page = Number(this.$route.query.page ? this.$route.query.page : 1)
                    this.type = this.$route.query.type || '1';
                    this.checkTableTitle()
                    this.Get_sampletype()
                },
                deep: true
            }
        },
        mounted() {
            this.page = Number(this.$route.query.page ? this.$route.query.page : 1)
            this.type = this.$route.query.type || '1';
            this.checkTableTitle()
            this.Get_sampletype()//获取基础字段列表
            //this.Create_sampletype()
        },
        methods: {
            saveItem(item) {
                let data = item
                console.log(data)
                // id: 39
                // is_del: 2
                // name: "5aname111"
                // sort: 5
                // type: 1

                this.$sa0.post({
                    url: this.$api('Edit_sampletype'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Get_sampletype()
                            this.$nextTick(() => {
                                this.$refs.clickPosition.focus()
                            })
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            delItem(item) {
                layer.confirm('是否删除？', {
                    btn: ['确定', '取消'] //按钮
                }, () => {
                    let data = {
                        id: item.id,
                        type: this.type,
                    };
                    this.$sa0.post({
                        url: this.$api('Del_sampletype'),
                        data: data,
                    }).then((response) => {
                        this.$sa0.response({
                            response: response.data,
                            then: (response) => {
                                layer.closeAll()
                                this.Get_sampletype()
                            },
                            error: (response) => {
                                layer.msg(response.message)
                            },
                        })
                    })
                }, function () {
                });
            },

            checkTableTitle() {
                let arr = ['', '样品分类', '检测能力分组', '存放地点', '试剂组别', '单位管理/试剂单位', '资质分类', '实验室分类', '仪器分类', '报损原因', '因子单位', '人员分类', '物品列表', '仪器条件', '实验类别'];
                return arr[Number(this.type)]
            },

            tabItemClick(type) {
                this.$router.push({query: {page: 1, type: type}})
            },
            // 分页
            onChange(page) {
                this.$router.push({
                    query: {
                        type: this.type,
                        page: page,
                    }
                })
            },
            //获取基础字段列表
            Get_sampletype() {
                this.$sa0.post({
                    url: this.$api('Get_sampletype'),
                    data: {
                        page: this.page,
                        type: this.type,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.ziduan_list = response.data.result.list;
                            this.count = Number(response.data.result.count);//分页
                            this.pagesize = Number(response.data.result.pagesize);//分页
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            Create_sampletype() {
                this.$sa0.post({
                    url: this.$api('Create_sampletype'),
                    data: {
                        name: this.create_data.name,
                        sort: this.create_data.sort,
                        type: this.type,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Get_sampletype()
                            this.create_data = {
                                name: '',
                                sort: 0
                            }
                            this.$nextTick(() => {
                                this.$refs.clickPosition.focus()
                            })
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            callback(key) {
                console.log('----')
                console.log(key);
            },
        },
    };
</script>
<style scoped>
    .bgwrap{
        width: 100%;
        background: #fdfdfd;
        padding: 20px;
        box-sizing: border-box
        }

    .ml20{
        margin-left: 20px
        }

    .w200{
        width: 200px
        }


</style>
